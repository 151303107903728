<template>
  <div class="page" v-if="mainPage">
    <ls-title title="档案记录"></ls-title>

    <div class="conddiv">
      <el-input v-model="condition" placeholder="搜索姓名/身份证" prefix-icon="el-icon-search" style="width:200px;" clearable></el-input>
      <el-date-picker v-model="dateTime" type="daterange" range-separator="至" format="YYYY 年 MM 月 DD 日"
              value-format="YYYY-MM-DD" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-left:5px;" clearable>
      </el-date-picker>
      <el-select v-model="userState" placeholder="用户状态" size="large" style="margin-left:5px;" clearable>
        <el-option v-for="item in userStateOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-button type="warning" style="margin-left:5px;" @click="search">搜索</el-button>
      <el-button v-if="role != 1"  type="primary" style="float:right;" @click="addRecord">新增记录</el-button>
    </div>

    <!-- 表格 -->
    <el-table :data="tableData" border style="width: 100%;border-radius: 10px;" :header-cell-style="{ background: '#285df0'}" :empty-text="emptyText">
      <el-table-column prop="createTime" label="日期时间" width="180" label-class-name="tablehead" align="center " />
      <el-table-column prop="name" label="姓名" label-class-name="tablehead" align="center" width="120" />
      <el-table-column prop="idcard" label="身份证" label-class-name="tablehead" align="center" width="180" />
      <el-table-column prop="desction" label="内容信息" label-class-name="tablehead" align="center " />
      <el-table-column prop="userState" label="用户状态" label-class-name="tablehead" align="center" width="100">
        <template #default="scope">
          <span v-if="scope.row.userState==1">可见</span>
          <span v-if="scope.row.userState==0">不可见</span>
        </template>
      </el-table-column>
      <el-table-column prop="recorder" label="记录人" label-class-name="tablehead" align="center" width="100" />
      <el-table-column label="操作" label-class-name="tablehead" align="center " width="100">
        <template #default="scope">
          <span class="textbtn" @click="details(scope.row)">详情</span>
          <span v-if="scope.row.isDelete==1 && role != 1"> / </span>
          <span v-if="scope.row.isDelete==1 && role != 1" class="textbtn" @click="deleteRecord(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="pagination">
      <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
    </div>
  </div>
  <template v-if="addPage">
    <ArchiveRecordAdd @update="update" @cancel="cancelAddPage"></ArchiveRecordAdd>
  </template>
  <template v-if="detailsPage">
    <ArchiveRecordDetails ref="childView" @update="update" @cancel="cancelDetailsPage"></ArchiveRecordDetails>
  </template>
</template>

<script>
import {getUserRecordInfos,deleteUserRecordInfo } from '../../../api/api'
import ArchiveRecordAdd from './ArchiveRecordAdd.vue'
import ArchiveRecordDetails from './ArchiveRecordDetails.vue'
import session from "../../../store/store";
import until from '../../../until/until'

export default {
  components: {
    ArchiveRecordAdd,
    ArchiveRecordDetails
  },
  data() {
    return {
      emptyText:'加载中',
      mainPage:true,
      addPage: false,
      detailsPage:false,
      condition: "",//姓名身份证
      dateTime: '',//条件
      startTime:'',
      endTime:'',
      role:0,
      currentPage: 1,
      size: 10,
      total: 0,//总数
      userState: '',//用户状态
      userStateOption:[
        {value: "1",label: '可见'},
        {value: "0",label: '不可见'}
      ],
      tableData: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.role = session.getLoginInfo().accountType;
      this.getUserRecordInfos();
    },
    search() {
      if (!until.isNULL(this.dateTime)) {
        this.startTime = this.dateTime[0],
        this.endTime = this.dateTime[1]
      }else {
        this.startTime = '',
        this.endTime = ''
      }
      this.getUserRecordInfos();
    },
    update(){
      this.getUserRecordInfos();
    },
    getUserRecordInfos() {
      let param = {
        uuid:session.getLoginInfo().unitUuid,
        role:this.role,
        page:this.currentPage - 1,
        size:this.size,
        condition:this.condition,
        startTime:this.startTime,
        endTime:this.endTime
      }
      if (!until.isNULL(this.userState)) {
        param.userState = this.userState
      }
      getUserRecordInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.tableData = res.data.dataInfo;
        }else {
          this.total = 0;
          this.tableData.splice(0, this.tableData.length);
          this.emptyText = "暂无数据"
        }
      })
    },

    deleteRecord(row) {
      this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var param = {
            unitUuid:session.getLoginInfo().unitUuid,
            uuid:row.uuid,
          }
          deleteUserRecordInfo(param).then(res => {
            if (res.data.code == 200) {
              this.$message({ message: '删除成功',  type: 'success'});
              this.getUserRecordInfos();
            } else {
              this.$message.error({ message: res.data.message});
            }
          })

        }).catch(() => {});
    },

    addRecord(){
      this.mainPage = false;
      this.addPage = true;
    },
    cancelAddPage() {
      this.mainPage = true;
      this.addPage = false;
    },
    details(row) {
      this.mainPage = false;
      this.detailsPage = true;
      setTimeout(() => {
        this.$refs.childView.getParentData(row);
      }, 200);
    },
    cancelDetailsPage() {
      this.mainPage = true;
      this.detailsPage = false;
    },

      //分页
    handleSizeChange(val) {
      this.size = val;
      this.getUserRecordInfos();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserRecordInfos();
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0px 20px;
}
.conddiv {
  background-color: white;
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: 0px 2px 3px 4px var(--el-color-info-light);
  margin-bottom: 20px;
}
.pagination {
  background-color: white;
  text-align: center;
  padding: 20px 0px;
  margin-top: 20px;
  border-radius: 10px;
}
.textbtn {
  color: #285df0;
  cursor: pointer;
}
</style>
<style>
/* .el-input__prefix {
  top: 5px !important;
} */
.tablehead {
  color: white;
}
</style>