<template>
  <div>
    <el-dialog v-model="userVisible" :close-on-click-modal="false"  :close-on-press-escape="false" :show-close="false" width="70%" >
      <template #title>
        <span style="color: white; font-size: 20px">选择用户</span>
        <el-button size="small" style="margin-left: 5px; float: right" @click="search">搜索</el-button>
        <el-input v-model="condition" placeholder="搜索姓名/身份证号码" prefix-icon="el-icon-search"  size="small" style="width: 200px; float: right">
        </el-input>
      </template>

        <el-table :data="tableData" border style="width: 100%; border-radius: 10px" empty-text="暂无数据">
          <el-table-column prop="name" label="姓名" width="180" align="center" />
          <el-table-column prop="age" label="年龄" width="60" align="center" />
          <el-table-column prop="sex" label="性别" width="60" align="center">
            <template #default="scope">
              <span v-if="scope.row.sex == 1">男</span>
              <span v-if="scope.row.sex == 0">女</span>
            </template>
          </el-table-column>
          <el-table-column prop="idcard" label="身份证号" align="center" />
          <el-table-column prop="address" label="居住地址" align="center">
            <template #default="scope">
              {{ scope.row.address != "" ? scope.row.address.replace(/#/g, "") : "--"}}
            </template>
          </el-table-column>
          <el-table-column label="选择" width="100" align="center ">
            <template #default="scope">
              <el-radio v-model="index" :label="scope.$index" >&nbsp;</el-radio>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination">
          <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
        </div> 
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancel" size="small">取消</el-button>
            <el-button type="primary" @click="sure" size="small">确定</el-button>
          </span>
        </template>
    </el-dialog>
  </div>
</template>

<script>
import {getUserInfos } from '../../../api/api'
import session from "../../../store/store";
export default {
  data() {
    return {
      condition: "",
      userVisible: true,
      index:-1,
      role:0,
      currentPage: 1,
      size: 10,
      total: 0, //总数
      tableData: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.role = session.getLoginInfo().accountType;
      this.getUserInfos();
    },
    search() {
      this.getUserInfos();
    },
    getUserInfos() {
      let param = {
        uuid:session.getLoginInfo().unitUuid,
        role:this.role,
        page:this.currentPage - 1,
        size:this.size,
        condition:this.condition,
      }
      getUserInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.tableData = res.data.dataInfo;
        }else {
          this.total = 0;
          this.tableData.splice(0, this.tableData.length);
        }
      })
    },
    sure() {
      if(this.index == -1) {
        this.$message.warning({ message: "请先选择用户"});
        return;
      }

      this.$emit("update",this.tableData[this.index]);
      this.cancel();
    },
    cancel() {
      this.userVisible = false;
      this.$emit("cancel");
    },
    handleSizeChange(val) {
      this.size = val;
      this.getUserInfos();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserInfos();
    },
  },
};
</script>

<style scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}
</style>