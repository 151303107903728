<template>
  <div class="page">
    <ls-title-header @back="cancel" title="新增记录" ></ls-title-header>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="180px" class="demo-ruleForm">
      <div style="background-color: white;">
        <div class="box">
          <el-row class="row">
            <el-col :span="12">
                <el-form-item prop="name">
                  <template #label><span class="title">姓&emsp;&emsp;名</span></template>
                  <el-input v-model="ruleForm.name" placeholder="请选择用户" class="input" disabled>
                    <template #append>
                      <el-button type="primary" style="color:white;" @click="this.userPage = true">选择用户</el-button>
                    </template>
                  </el-input>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">身&ensp;份&ensp;证</span></template>
                  <span class="content">{{userData.idcard}}</span>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">性&emsp;&emsp;别</span></template>
                    <span class="content" v-if="userData.sex == 1">男</span>
                    <span class="content" v-if="userData.sex == 0">女</span>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">年&emsp;&emsp;龄</span></template>
                  <span class="content">{{userData.age}}</span>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">民&emsp;&emsp;族</span></template>
                   <span class="content">{{userData.nation}}</span>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">出生年月</span></template>
                   <span class="content">{{userData.birthday}}</span>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">居住地址</span></template>
                   <span class="content">{{userData.address != "" ? userData.address.replace(/#/g, "") : "--"}}</span>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">用户状态</span></template>
                  <el-select v-model="ruleForm.userState" placeholder="用户状态" size="large" style="margin-left:5px;">
                    <el-option v-for="item in userStateOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
              <el-form-item prop="recorder">
                  <template #label><span class="title">记&ensp;录&ensp;人</span></template>
                  <el-input v-model="ruleForm.recorder" placeholder="请输入记录人" maxlength="10" show-word-limit class="input"></el-input>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="desction">
                  <template #label><span class="title">信息内容</span></template>
                  <el-input  type="textarea" v-model="ruleForm.desction" placeholder="请输入信息内容" maxlength="200" show-word-limit class="input"></el-input>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">记录图片</span></template>
                  <ls-file-upload :uploadUrl="getUploadUrl()" :limitCount="3" :data="fileData" :files="fileList" @uploadSuccess="uploadSuccess" @deleteFile="deleteImage"></ls-file-upload>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
              <el-form-item>
                <el-button type="primary" size="small" @click="submitForm('ruleForm')">提交</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>

    <template v-if="userPage">
      <ArchiveNewUser @update="update" @cancel="this.userPage = false"></ArchiveNewUser>
    </template>
  </div>

 
</template>

<script>
import {fileUpload,fileDelete,addUserRecordInfo } from '../../../api/api'
import session from "../../../store/store";
import ArchiveNewUser from "./ArchiveNewUser.vue";
export default {
  components: {
    ArchiveNewUser,
  },
  data() {
    return {
      userPage:false,
      fileList: [],//页面显示
      imageList:[],//上传需要
      fileData:{},
      ruleForm: {
        name: '',
        userUuid:'',
        userState:1,
        recorder:'',
        desction:'',
      },
      userData:{
        address:'',
      },
      userStateOption:[{
          value: 1,
          label: '可见'
        },{
          value: 0,
          label: '不可见'
        }
      ],
      rules: {
        name: [
          {required: true,message: '请输入姓名', trigger: 'blur',}
        ],
        recorder: [
          {required: true,message: '请输入记录人', trigger: 'blur',}
        ],
        desction: [
          {required: true,message: '请添加信息', trigger: 'blur',}
        ],
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.fileData.directory = session.getLoginInfo().unitUuid
    },
    update(data) {
      this.userData = data;
      this.ruleForm.name = data.name;
      this.ruleForm.userUuid = data.uuid;
    },

    submitForm(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
         this.addRecord();
        } else {
          return false;
        }
      });
    },

    addRecord() {
      var param = {
        userUuid:this.ruleForm.userUuid,
        uuid:session.getLoginInfo().unitUuid,
        desction:this.ruleForm.desction,
        appShowSwitch:this.ruleForm.userState,
        recorder:this.ruleForm.recorder,
      }

      if(this.imageList.length > 0) {
        param.imageInfos = JSON.stringify(this.imageList);
      }
      addUserRecordInfo(param).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '新增成功',  type: 'success'});
          this.cancel();
          this.close();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    },

    cancel() {
      this.$emit('cancel')
    },

    close() {
      this.$emit('update')
    },

    getUploadUrl() {
      return fileUpload();
    },
     // 图片上传成功
    uploadSuccess(e) {
      this.imageList.push(e.dataInfo)
    },
    //图片删除
    deleteImage(e, callback) {
      let param = {
        filePath:e.response.filePath,
      }
      fileDelete(param).then(res => {
        if (res.data.code == 200) {
          this.$message.success("删除成功");
          this.imageList.forEach((data,index) => {
            if(data.filePath == e.response.filePath) {
              this.imageList.splice(index,1)
              return;
            }
          });

          callback(res);
        } else {
          this.$message.error(res.data.message);
        }
      })
    },

  }
}
</script>

<style lang="scss" scoped>

.page {
  padding: 0px 20px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 100%;
}
hr {
  border-color: #f4f4f526;
}
.page {
  padding: 0px 20px;
}
.title {
  margin-right: 25px;
  font-size: 16px;
  color: #8D8D8D;
}
.box {
  padding: 20px;
  width: 75%;
  background-color: white;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.row {
  margin: 20px 0px 20px 0px;
}
</style>